import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { CustomTypeOptions } from 'i18next';
import {
  BankIcon,
  PlugIcon,
  InvoiceIcon,
  HeartPlusIcon,
  ArrowRightIcon,
  HomeFilledIcon,
  CardsFilledIcon,
  BackspaceFilledIcon,
  HomeIcon,
  CardsIcon,
  InsightsFilledIcon,
  ArrowLoopRoundRightIcon,
  InsightsIcon,
  PaymentsIcon,
  ProductsIcon,
  GlobeIcon,
  BetalingsserviceIcon,
} from '@lunarway/matter-web';

import { useFeatureToggleAnyMatchLazy } from '_src/app/feature_toggle';
import { FeatureToggle } from '_src/app/feature_toggle/domain/types';

export type AppNavigationCategoryDef = {
  id: string;
  type: 'category';
  icon: ReactElement;
  localiseKey: keyof CustomTypeOptions['resources']['app_navigation']['app_navigation']['items'];
  children: Array<AppNavigationItemDef>;
};

export type AppNavigationItemDef = {
  id: string;
  type: 'link';
  icon: ReactElement;
  activeIcon: ReactElement;
  localiseKey: keyof CustomTypeOptions['resources']['app_navigation']['app_navigation']['items'];
  pattern: RegExp;
  route: string;
  features: Array<FeatureToggle>;
};

export type AppNavigationCategory = {
  id: string;
  icon: ReactElement;
  title: string;
  children: Array<AppNavigationItem>;
};

export type AppNavigationItem = {
  id: string;
  icon: ReactElement;
  activeIcon: ReactElement;
  title: string;
  pattern: RegExp;
  route: string;
};

export const appNavigationItems: Array<
  AppNavigationCategoryDef | AppNavigationItemDef
> = [
  {
    type: 'link',
    route: '/home',
    icon: <HomeIcon />,
    activeIcon: <HomeFilledIcon />,
    localiseKey: 'home',
    id: 'app-navigation-home',
    pattern:
      /^(\/home)|^(\/account\/\[accountId\])|^(\/automation\/\[accountId\](\/create)?)$/m,
    features: [
      FeatureToggle.TerraAccountDetails,
      FeatureToggle.TerraAccountsView,
      FeatureToggle.TerraLatestTransactions,
    ],
  },
  {
    type: 'category',
    icon: <PaymentsIcon />,
    localiseKey: 'payments',
    id: 'app-navigation-payments',
    children: [
      {
        type: 'link',
        icon: <InvoiceIcon />,
        activeIcon: <BackspaceFilledIcon />,
        route: '/bill-management',
        localiseKey: 'bill_management',
        id: 'app-navigation-bill-management',
        pattern: /^(\/bill-management)$/m,
        features: [FeatureToggle.TerraBillManagement],
      },
      {
        type: 'link',
        icon: <ArrowRightIcon />,
        activeIcon: <BackspaceFilledIcon />,
        route: '/send-money',
        localiseKey: 'send_money',
        id: 'app-navigation-send-money',
        pattern: /^(\/send-money)$/m,
        features: [FeatureToggle.TerraPaymentsTransferDomestic],
      },
      {
        type: 'link',
        icon: <GlobeIcon />,
        activeIcon: <BackspaceFilledIcon />,
        route: '/international-transfer',
        localiseKey: 'international_transfer',
        id: 'app-navigation-international-transfer',
        pattern: /^(\/international-transfer)$/m,
        features: [FeatureToggle.TerraPaymentsTransferInternational],
      },
      {
        type: 'link',
        icon: <InvoiceIcon />,
        activeIcon: <BackspaceFilledIcon />,
        route: '/pay-bill',
        localiseKey: 'pay_bill',
        id: 'app-navigation-pay-bill',
        pattern: /^(\/pay-bill)$/m,
        features: [
          FeatureToggle.TerraPaymentsPayBillInvoice,
          FeatureToggle.TerraPaymentsPayBillPaymentSlip,
        ],
      },
      {
        type: 'link',
        icon: <ArrowLoopRoundRightIcon />,
        activeIcon: <BackspaceFilledIcon />,
        route: '/recurring-transfers',
        localiseKey: 'standing_orders',
        id: 'app-navigation-standing-orders',
        pattern: /^(\/recurring-transfers)$/m,
        features: [FeatureToggle.TerraPaymentsStandingOrders],
      },
    ],
  },
  {
    type: 'link',
    route: '/cards',
    icon: <CardsIcon />,
    activeIcon: <CardsFilledIcon />,
    localiseKey: 'cards',
    id: 'app-navigation-cards',
    pattern: /^(\/cards)$/m,
    features: [FeatureToggle.TerraCards],
  },
  {
    type: 'link',
    route: '/insights',
    icon: <InsightsIcon />,
    activeIcon: <InsightsFilledIcon />,
    localiseKey: 'insights',
    id: 'app-navigation-insights',
    pattern: /^(\/insights)$/m,
    features: [FeatureToggle.TerraInsightsBiggestMerchants],
  },
  {
    type: 'category',
    icon: <ProductsIcon />,
    localiseKey: 'discover',
    id: 'app-navigation-products',
    children: [
      {
        type: 'link',
        icon: <BetalingsserviceIcon />,
        activeIcon: <BackspaceFilledIcon />,
        route: '/betalingsservice',
        localiseKey: 'direct_debit',
        id: 'app-navigation-direct_debit',
        pattern: /^(\/betalingsservice)$/m,
        features: [FeatureToggle.TerraDirectDebit],
      },
      {
        type: 'link',
        icon: <HeartPlusIcon />,
        activeIcon: <BackspaceFilledIcon />,
        route: '/insurances',
        localiseKey: 'insurance',
        id: 'app-navigation-insurance',
        pattern: /^(\/insurances)$/m,
        features: [FeatureToggle.TerraInsurance],
      },
      {
        type: 'link',
        icon: <BankIcon />,
        activeIcon: <BackspaceFilledIcon />,
        route: '/loans',
        localiseKey: 'loans',
        id: 'app-navigation-loans',
        pattern: /^(\/loans)$/m,
        features: [FeatureToggle.TerraLoans],
      },
      {
        type: 'link',
        icon: <PlugIcon />,
        activeIcon: <BackspaceFilledIcon />,
        route: '/integrations',
        localiseKey: 'integrations',
        id: 'app-navigation-integrations',
        pattern: /^\/integrations(\/.*)?$/m,
        features: [
          FeatureToggle.TerraAccountingIntegrationsBilly,
          FeatureToggle.TerraAccountingIntegrationsDinero,
          FeatureToggle.TerraAccountingIntegrationsEAccounting,
          FeatureToggle.TerraAccountingIntegrationsEconomic,
          FeatureToggle.TerraAccountingIntegrationsFortnox,
        ],
      },
      {
        type: 'link',
        icon: <ArrowRightIcon />,
        activeIcon: <BackspaceFilledIcon />,
        route: '/nemkonto',
        localiseKey: 'nemkonto',
        id: 'app-navigation-nemkonto',
        pattern: /^(\/nemkonto)$/m,
        features: [
          FeatureToggle.TerraNemKonto,
          FeatureToggle.TerraNemKontoManage,
        ],
      },
    ],
  },
];

export const useAppNavigationItems = (): Array<
  AppNavigationItem | AppNavigationCategory
> => {
  const { t } = useTranslation('app_navigation');
  const featureToggleAnyMatch = useFeatureToggleAnyMatchLazy();
  return useMemo(() => {
    const roots = appNavigationItems
      .filter((item) => {
        if (item.type === 'category') {
          return item.children.some((child) =>
            featureToggleAnyMatch(child.features)
          );
        }

        return featureToggleAnyMatch(item.features);
      })
      .map((item) => {
        if (item.type === 'category') {
          return {
            id: item.id,
            type: item.type,
            icon: item.icon,
            title: t(`app_navigation.items.${item.localiseKey}`),
            children: item.children
              .filter((child) => featureToggleAnyMatch(child.features))
              .map((child) => ({
                id: child.id,
                icon: child.icon,
                activeIcon: child.activeIcon,
                title: t(`app_navigation.items.${child.localiseKey}`),
                pattern: child.pattern,
                route: child.route,
              })),
          };
        }

        return {
          id: item.id,
          type: item.type,
          icon: item.icon,
          activeIcon: item.activeIcon,
          title: t(`app_navigation.items.${item.localiseKey}`),
          pattern: item.pattern,
          route: item.route,
        };
      });
    if (roots.length === 1 && roots[0].type === 'category') {
      return roots[0].children;
    }

    return roots;
  }, [t, featureToggleAnyMatch]);
};
